import Head from 'next/head';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { Box, Button, makeStyles } from 'groundkeeper-component-library';

import Globals from 'shared/App/utils/Globals';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100vh'
  },
  headingBox: {
    fontSize: '10em',
    color: theme.color.primary.main
  },
  messageBox: {
    fontSize: '1.5em'
  }
}));

function PageNotFound(props) {
  const intl = useIntl();
  const router = useRouter();
  const classes = useStyles(props);

  return (
    <>
      <Head>
        <title>
          {Globals.settings.appEnv ? `${Globals.settings.appEnv} ` : ''}
          {`${intl.formatMessage({
            id: 'error_page_not_found',
            defaultMessage: "This page doesn't seem to exist."
          })} ${intl.formatMessage({
            id: 'application_base_title',
            defaultMessage: '- Management portal'
          })}`}
        </title>
      </Head>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={classes.wrapper}
      >
        <Box className={classes.headingBox}>404</Box>
        <Box className={classes.messageBox} mb={10}>
          {intl.formatMessage({
            id: 'error_page_not_found',
            defaultMessage: "This page doesn't seem to exist."
          })}
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => router.push('/dashboard')}
          >
            {intl.formatMessage({
              id: 'back_home',
              defaultMessage: 'Back home'
            })}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default PageNotFound;
